/* HTML: <div class="loader"></div> */
.homeLoader {
    --w:30ch;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    line-height: 1.4em;
    letter-spacing: var(--w);
    width: var(--w);
    overflow: hidden;
    white-space: nowrap;
    color: #0000;
    text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000, 
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000;
    animation: l20 2s infinite linear;
  }
  .homeLoader:before {
    content:"MUSTO PHONES AND ACCESSORIES...";
  }
  
  @keyframes l20 {
    9.09% {text-shadow: 
          calc( 0*var(--w)) -10px #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) -10px #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    18.18% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) -10px #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) -10px #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    27.27% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) -10px #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) -10px #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    36.36% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) -10px #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) -10px #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    45.45% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) -10px #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) -10px #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    54.54% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) -10px #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) -10px #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) -10px #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) -10px #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    63.63% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) -10px #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) -10px #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) -10px #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) -10px #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    72.72% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) -10px #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) -10px #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) -10px #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) 0 #000}
    81.81% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) -10px #000,calc(-9*var(--w)) 0 #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) -10px #000,
          calc(-20*var(--w)) 0 #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) -10px #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) -10px #000,calc(-27*var(--w)) 0 #000,calc(-28*var(--w)) -10px #000,calc(-29*var(--w)) 0 #000}
    90.90% {text-shadow: 
          calc( 0*var(--w)) 0 #000,calc(-1*var(--w)) 0 #000,calc(-2*var(--w)) 0 #000,calc(-3*var(--w)) 0 #000,calc(-4*var(--w)) 0 #000, 
          calc(-5*var(--w)) 0 #000,calc(-6*var(--w)) 0 #000,calc(-7*var(--w)) 0 #000,calc(-8*var(--w)) 0 #000,calc(-9*var(--w)) -10px #000,
          calc(-10*var(--w)) 0 #000,calc(-11*var(--w)) 0 #000,calc(-12*var(--w)) 0 #000,calc(-13*var(--w)) 0 #000,calc(-14*var(--w)) 0 #000,
          calc(-15*var(--w)) 0 #000,calc(-16*var(--w)) 0 #000,calc(-17*var(--w)) 0 #000,calc(-18*var(--w)) 0 #000,calc(-19*var(--w)) 0 #000,
          calc(-20*var(--w)) -10px #000,calc(-21*var(--w)) 0 #000,calc(-22*var(--w)) 0 #000,calc(-23*var(--w)) 0 #000,calc(-24*var(--w)) 0 #000,
          calc(-25*var(--w)) 0 #000,calc(-26*var(--w)) 0 #000,calc(-27*var(--w)) -10px #000,calc(-28*var(--w)) 0 #000,calc(-29*var(--w)) -10px #000}
  }