.container {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.enter {
    transition: border-color 0.3s ease-in-out;
}

.enter:focus {
    border-color: #f00; /* Change border color on focus */
}

.bt {
    transition: background-color 0.3s ease-in-out, transform 0.2s ease;
}

.bt:hover {
    background-color: #c00; /* Darker red on hover */
    transform: translateY(-2px); /* Slight lift effect */
}
