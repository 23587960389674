.customShadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    box-sizing: border-box;
}

*, ::before, ::after {
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 100vw; 
    padding: 0 15px; 
    
}

@media (max-width: 370px) {
    .header, .footer {
        padding: 10px; /* Adjust padding for smaller screens */
    }
    .container {
        padding: 0 10px; /* Less padding on small screens */
        width: 100%;
        max-width: 370px;
    }
}

/***user table***/
.userTable {
    @apply bg-white
}
.userTable th{
    @apply border text-base font-medium
}
.userTable td{
    @apply border text-base text-center
}

/***scrollbar hidden***/
.scrollbar-none::-webkit-scrollbar {
    display: none;

}

.chat-icon {
    background-color: rgb(2, 255, 15);
    border-radius: 50%;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000; 
}




.faq-popup {
    position: fixed;
    max-height: 450px;
    bottom: 60px;
    right: 20px;
    width: 400px; 
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; 
    padding: 10px;
}

.close-faq {
    background-color: #f44336; /* Red */
    color: white;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 3px;
    margin-left: 350px;
}

.close-faq:hover {
    background-color: #d32f2f; /* Darker red */
}

/* styles.css */

/* Light Theme */
/* .light {
    background-color: rgb(245, 236, 236);
    color: black;
} */

/* Dark Theme */
.dark {
    background-color: #121212; /* Dark background */
    color: white; /* Light text */
}

/* Additional styles for buttons, inputs, etc. */
.theme-toggle {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.theme-toggle:hover {
    background-color: #0056b3;
}
