.chat-container {
    max-width: 400px; /* Adjust width as needed */
    max-height: 400px; /* Increased height to accommodate fixed input */
    overflow: hidden; /* Prevent overflow */
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Use flexbox for layout */
}

.chat-box {
    flex: 1; /* Allow chat box to grow */
    overflow-y: auto; /* Allow scrolling */
    scrollbar-width: none;
    margin-bottom: 10px; /* Space for input */
}

.chat-message {
    margin: 5px 0;
    padding: 8px;
    border-radius: 5px;
}

.chat-message.user {
    background-color: #e1f5fe;
    text-align: right;
}

.chat-message.bot {
    background-color: #ffe0b2;
}

.input-container {
    display: flex;
    justify-content: space-between;
    margin-top: auto; /* Push input to the bottom */
}

.input-container input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.input-container button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.input-container button:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.suggestions {
    margin-top: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
}

.suggestion {
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
}

.suggestion:hover {
    background-color: #e0e0e0;
}

.faq-quick-questions {
    margin-top: 10px;
    text-align: center;
}

.quick-question {
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.quick-question:hover {
    background-color: #e0e0e0;
}
