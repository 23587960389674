/* author: Ecem Gokdogan
https://codepen.io/ecemgo/pen/Baqpbez */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'poppins',sans-serif;
}

#bg {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url(../../assest/loginbg.avif);
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: cover;
  }

#login {
    position: relative;
    max-width: 400px;
    background-color: transparent;
    border: 2px solid rgb(252, 250, 250);
    border-radius: 20px;
    backdrop-filter: blur(55px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
}

#login h1 {
    font-size: 2rem;
    color: #fffdfd;
    text-align: center;
}

#login .inputbox {
    position: relative;
    margin: 30px 0;
    max-width: 310px;
    border-bottom: 2px solid #fdfcfc;
}

#login .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #faf9f9;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

#login input:focus ~ label, 
#login input:valid ~ label {
    top: -5px;
}

#login .inputbox input {
    width: 100%;
    height: 60px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #f8f6f6;
}

#login .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: #fdfcfc;
    font-size: 1.2rem;
    top: 20px;
}

#login .forget {
    margin: 35px 0;
    font-size: 0.85rem;
    color: #fdfcfc;
    display: flex;
    justify-content: space-between;
 
}

#login .forget label {
    display: flex;
    align-items: center;
}

#login .forget label input {
    margin-right: 3px;
}

#login .forget a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

#login .forget a:hover {
    text-decoration: underline;
}

#login button {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: rgb(254, 254, 255);
    color: #0a0a0a;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.4s ease;
}

#login button:hover {
  background-color: rgba(173, 173, 175, 0.568);
}

#login .register {
    font-size: 0.9rem;
    color: #fffdfd;
    text-align: center;
    margin: 25px 0 10px;
}

#login .register p a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

#login .register p a:hover {
    text-decoration: underline;
}