.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
}

.modal-content {
    background-color: white;
    padding: 30px; /* Increased padding for more space */
    border-radius: 8px; /* Rounded corners */
    width: 500px; /* Increased width for larger modal */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.close {
    cursor: pointer;
    float: right;
    font-size: 24px; /* Increased font size for close button */
    color: #f44336; /* Red color for close button */
}

.close:hover {
    color: #d32f2f; /* Darker red on hover */
}

.form {
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
}

.input, .select {
    padding: 12px; /* Increased padding for inputs and selects */
    margin-bottom: 15px; /* Space between form elements */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px; /* Increased font size for better readability */
}

.btn {
    padding: 12px; /* Increased padding for button */
    background-color: #2196F3; /* Blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Added transition for hover effect */
}

.btn:hover {
    background-color: #1976D2; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}
