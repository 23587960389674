/* AllBanners.css */
.banner-list {
    margin-top: 20px;
}

.banner-item {
    position: relative;
    transition: transform 0.2s;
}

.banner-item:hover {
    transform: scale(1.05);
}

.banner-item img {
    border-radius: 8px;
}

