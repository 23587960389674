.delivery-address-container {
    padding: 40px; /* Increased padding for better spacing */
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; /* Increased margin for better spacing */
}

.add-btn, .edit-btn {
    padding: 15px 30px; /* Increased padding for larger buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Added transform for hover effect */
}

.add-btn {
    background-color: #f44336; /* Red */
    color: white;
}

.add-btn:hover {
    background-color: #d32f2f; /* Darker red on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.edit-btn {
    background-color: #2196F3; /* Blue */
    color: white;
}

.edit-btn:hover {
    background-color: #1976D2; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.address-display {
    border: 1px solid #ccc;
    padding: 20px; /* Increased padding for better spacing */
    border-radius: 5px;
    background-color: #fff; /* White background for address display */
}

.address-info {
    font-size: 18px; /* Increased font size for better readability */
    line-height: 1.5; /* Improved line height for readability */
}
