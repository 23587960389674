/* author: Ecem Gokdogan
https://codepen.io/ecemgo/pen/Baqpbez */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'poppins',sans-serif;
}

#bg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url(../../assest/loginbg.avif);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
}

#signup {
    position: relative;
    max-width: 400px;
    background-color: transparent;
    border: 2px solid rgb(253, 252, 252);
    border-radius: 20px;
    backdrop-filter: blur(55px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
}

#signup h1 {
    font-size: 2rem;
    color: #fff;
    text-align: center;
}

#signup .inputbox {
    position: relative;
    margin: 10px 0;
    max-width: 310px;
    border-bottom: 2px solid #fff;
}

#signup .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

#signup input:focus ~ label, 
#signup input:valid ~ label {
    top: -5px;
}

#signup .inputbox input {
    width: 100%;
    height: 60px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #fff;
}

#signup .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2rem;
    top: 20px;
}

#signup .forget {
    margin: 35px 0;
    font-size: 0.85rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
 
}

#signup .forget label {
    display: flex;
    align-items: center;
}

#signup .forget label input {
    margin-right: 3px;
}

#signup .forget a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

#signup .forget a:hover {
    text-decoration: underline;
}

#signup button {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: rgb(253, 253, 253);
    color: #050000;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.4s ease;
}

#signup button:hover {
  background-color: rgba(250, 250, 250, 0.493);
}

#signup .register {
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    margin: 25px 0 10px;
}

#signup .register p a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

#signup .register p a:hover {
    text-decoration: underline;
}