/* AllBanners.css */
.modal {
    position: fixed; /* Fixed positioning to cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Higher z-index to overlay other content */
}

