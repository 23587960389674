#subscribe .inputbox {
    position: relative;
    margin: 10px 0;
    max-width: 310px;
    border-bottom: 2px solid #fff;
}

#subscribe .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

#subscribe input:focus ~ label, 
#subscribe input:valid ~ label {
    top: -5px;
}

#subscribe .inputbox input {
    width: 100%;
    height: 60px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #fff;
}

#subscribe .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2rem;
    top: 20px;
}